// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-realizations-js": () => import("./../../../src/templates/realizations.js" /* webpackChunkName: "component---src-templates-realizations-js" */),
  "component---src-templates-references-js": () => import("./../../../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-single-realization-js": () => import("./../../../src/templates/single-realization.js" /* webpackChunkName: "component---src-templates-single-realization-js" */),
  "component---src-templates-single-reference-js": () => import("./../../../src/templates/single-reference.js" /* webpackChunkName: "component---src-templates-single-reference-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static_page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank_you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

